//General Imports
import React, { useEffect, useState, useContext } from "react";
import { graphql, Script } from "gatsby";
import parse, { HTMLReactParserOptions } from "html-react-parser";
import _ from "lodash";
import Seo from "gatsby-plugin-wpgraphql-seo";
import loadable from "@loadable/component";
import { IntlProvider } from "react-intl";
import { isBrowser, isMobilePredicate } from "../utils";

//Context
import LocalesContext from "../context/LocalesContext";
import TrackingContext from "../context/TrackingContext";

//Layout
import Layout from "../components/Layout";
import SeoStructure from "../components/SeoStructure";
import HeaderScripts from "../components/HeadScripts";
import TaboolaWebPush from "../components/vendor/TaboolaWebPush";

//Widgets
import TaboolaWidget, { TaboolaMode } from "../components/vendor/TaboolaWidget";
const Vidazoo = loadable(() => import("../components/vendor/Vidazoo"));

// Types
import type { BlogPostProps } from "./template.types";

export function Head({ data, pageContext }: BlogPostProps) {
  const post = data.posts.nodes[0];
  const canonicalSlug = `/${pageContext.slug}`;
  const excerptWithoutTags = post.excerpt?.replace(/(<([^>]+)>)/gi, "");

  return (
    <>
      <HeaderScripts />
      <Script
        async
        src="https://boot.pbstck.com/v1/tag/8c704138-0cf5-43be-9bf5-416e674a06d9"
        type="text/javascript"
      />
      <SeoStructure
        title={post.title}
        description={excerptWithoutTags ? excerptWithoutTags : ""}
        slug={`${pageContext.locale}${canonicalSlug}`}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            "@id": `${!post?.id ? "" : post?.id}`,
            datePublished: `${new Date(post?.date || "")}`,
            dateModified: `${new Date(post?.modified || "")}`,
            headline: `${!post?.title ? "" : post?.title}`,
            author: {
              "@type": "Person",
              name: `${
                !post?.author?.node?.name ? "" : post?.author?.node?.name
              }`,
            },
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `${!post?.slug ? "" : post?.slug}`,
            },
            publisher: {
              "@type": "Organization",
              name: `${
                !process.env.GATSBY_SITE_NAME
                  ? ""
                  : process.env.GATSBY_SITE_NAME
              }`,
              logo: {
                "@type": "ImageObject",
                url: "",
              },
            },
            image: {
              "@type": "ImageObject",
              url: `${
                !post?.featuredImage?.node?.link
                  ? ""
                  : post?.featuredImage?.node?.link
              }`,
              width: `${
                !post?.featuredImage?.node?.width
                  ? ""
                  : post?.featuredImage?.node?.width
              }`,
              height: `${
                !post?.featuredImage?.node?.height
                  ? ""
                  : post?.featuredImage?.node?.height
              }`,
            },
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: `${post.title}`,
              },
            ],
          })}
        </script>
      </SeoStructure>
    </>
  );
}

// Flag to insert ads just for first image
let imageAdsInserted = false;

function BlogPost(props: BlogPostProps): React.JSX.Element | null {
  const data = props.data;
  const post = data.posts.nodes[0];
  const { abtest } = useContext(TrackingContext);
  const [isMobile, setIsMobile] = useState(false);
  const [utm, setUtm] = useState("");

  useEffect(() => {
    setUtm(window.localStorage.getItem("utm_source") || "");
    imageAdsInserted = false;
    setIsMobile(isMobilePredicate());
  }, []);

  if (_.isEmpty(post)) return null;

  const options: HTMLReactParserOptions = {
    replace: (node) => {
      if (!("name" in node) || !("attribs" in node)) {
        return;
      }

      if (!isBrowser() || !isMobile || imageAdsInserted) {
        return;
      }

      if (node.attribs && node.attribs.src) {
        imageAdsInserted = true;

        return (
          <>
            <div className="code-block code-block--banner">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="LeaderBelowTitle" className="ad-container" />
            </div>
            <img
              src={node.attribs.src}
              referrerPolicy="no-referrer"
              alt=""
              //loading={index === 0 ? "eager" : "lazy"} Left to revisit
              width={node.attribs.width ? node.attribs.width : ""}
              height={node.attribs.height ? node.attribs.height : ""}
              srcSet={node.attribs.srcSet ? node.attribs.srcSet : ""}
            />
            <div className="code-block code-block--banner">
              <div className="divider">
                <span> Advertisement</span>
              </div>
              <div id="MPUTopRight" className="ad-container" />
            </div>
          </>
        );
      }

      if (node.attribs && node.attribs.src) {
        imageAdsInserted = true;

        return (
          <>
            <div className="code-block code-block--banner">
              <div className="divider">
                <span> Advertisement</span>
              </div>
              <div id="LeaderBelowTitle" className="ad-container" />
            </div>
            <img src={node.attribs.src} alt="" />
            <div className="code-block code-block--banner">
              <div className="divider">
                <span> Advertisement</span>
              </div>
              <div id="MPUTopRight" className="ad-container" />
            </div>
          </>
        );
      }

      return;
    },
  };

  return (
    <>
      <Seo post={post} />
      <article className="post post--standard">
        <div className="post-main">
          <header className="post-header">
            <h1 className="entry-title">{post.title}</h1>
            <div className="post-meta">
              <div className="post-author-name">
                <div className="author-by">By</div>
                <span>{post?.author?.node?.name}</span>
                <div className="author-line"> - </div>
              </div>
              <div className="post-date">{post.date}</div>
            </div>
          </header>

          <div className="post__video-player">
            <Vidazoo vidazooId="659d69a7ca46d867c34ed89b" />
          </div>

          <div className="post-content">
            {post.content && parse(post.content, options)}
          </div>

          <TaboolaWidget
            mode={"thumbnails-b" as TaboolaMode}
            container="taboola-below-article"
            placement="Widget Below Next"
            utm={utm}
          />
        </div>
        <aside className="post-ads-sidebar col-1">
          <div
            className="post-ads-sidebar__inner"
            style={{ marginTop: "10px" }}
          >
            <div className="divider">
              <span>Advertisement</span>
            </div>
            <div
              id="RightColumn"
              className="ad-container ad-container--sidebar"
            />
          </div>
        </aside>
      </article>
    </>
  );
}

const BlogPosts = (props: BlogPostProps): React.JSX.Element => {
  const avaiableLangs = [props.pageContext.locale];

  return (
    <>
      <TaboolaWebPush />
      <IntlProvider
        locale={props.pageContext.locale}
        messages={props.pageContext.messages}
      >
        <LocalesContext.Provider
          value={{
            slug: props.pageContext.slug,
            avaiableLangs,
            pageNumber: 0,
          }}
        >
          <Layout containerClasses="container--center" type="post">
            <BlogPost {...props} />
          </Layout>
        </LocalesContext.Provider>
      </IntlProvider>
    </>
  );
};

export default BlogPosts;

export const query = graphql`
  query ($slug: String!) {
    avaiablePostTranslations: allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        language {
          slug
        }
      }
    }
    posts: allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        title
        content
        nextPageTC
        slug
        excerpt
        featuredImage {
          node {
            link
            height
            width
          }
        }
        date(formatString: "MMMM D, YYYY")
        modified(formatString: "MMMM D, YYYY")
        author {
          node {
            name
          }
        }
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        language {
          slug
        }
      }
    }
  }
`;
