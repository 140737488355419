import React, { memo } from "react";
import { Script } from "gatsby";

export enum TaboolaMode {
  "thumbnails-b" = "thumbnails-b",
  "thumbnails-c" = "thumbnails-c",
  "thumbnails-d" = "thumbnails-d",
}
interface TaboolaWidgetProps {
  mode: TaboolaMode;
  container: string;
  placement: string;
  utm: string;
  className?: string;
}

const TaboolaWidget = memo(function ({
  mode,
  container,
  placement,
  utm,
  className,
}: TaboolaWidgetProps): React.JSX.Element {
  return (
    <div>
      <Script strategy="idle" type="text/javascript">
        {`
          window._taboola = window._taboola || [];
          _taboola.push({article:'auto', tracking_codes:{ 'utm_source': '${utm}'} });
          !function (e, f, u, i) {
          if (!document.getElementById(i)){
              e.async = 1;
              e.src = u;
              e.id = i;
              f.parentNode.insertBefore(e, f);
          }
          }(document.createElement('script'),
          document.getElementsByTagName('script')[0], 
          '//cdn.taboola.com/libtrc/trendscatchers-network/loader.js',
          'tb_loader_script');
          if(window.performance && typeof window.performance.mark == 'function')
          {window.performance.mark('tbl_ic');}    
        `}
      </Script>

      <Script strategy="idle" type="text/javascript">
        {`
          window._taboola = window._taboola || [];
          window._taboola.push({
              external_page_view:
                  ((window.performance.timing.navigationStart + window.performance.now()) * Math.random() * 1000000).toFixed() +
                  "|" +
                  window.location.host,
          });
          window._taboola.push({
              mode: '${mode}',
              container: '${container}',
              placement: '${placement}',
              target_type: 'mix',
              tracking_codes: "${utm}"

          });
        `}
      </Script>
      <Script strategy="idle" type="text/javascript">
        {`
          window._taboola = window._taboola || [];
          _taboola.push({flush: true});
        `}
      </Script>
      <div id={container} className={className}></div>
    </div>
  );
});

export default TaboolaWidget;
